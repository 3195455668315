const LIBRARY_READ = 'Library.Read'
const LIBRARY_WRITE = 'Library.Write'
const STUDY_READ = 'Study.Read'
const STUDY_WRITE = 'Study.Write'

export default {
  LIBRARY_READ,
  LIBRARY_WRITE,
  STUDY_READ,
  STUDY_WRITE
}
