import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-click":false,"close-on-content-click":false,"max-height":"800px","max-width":"500px","offset-y":"","content-class":"right"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-help-circle-outline")])],1)],1)]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{attrs:{"max-width":"500px"}},[_c(VCardTitle,{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.$t('_global.online_help'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","icon":""},on:{"click":function($event){_vm.open = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),_c(VList,[[_c(VListItem,[_c(VListItemContent,[_c(VExpansionPanels,_vm._l((_vm.items),function(item){return _c(VExpansionPanel,{key:item.key || item},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm.getItemLabel(item))+" ")]),_c(VExpansionPanelContent,[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getItemHelp(item))}})])],1)}),1)],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }