import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('simple-form-dialog',{ref:"form",attrs:{"title":_vm.title,"help-items":_vm.helpItems,"open":_vm.open},on:{"close":_vm.close,"submit":_vm.submit},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('validation-observer',{ref:"observer"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Project","vid":"project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{attrs:{"label":_vm.$t('StudyForm.project_id'),"items":_vm.projects,"item-text":"project_number","return-object":"","error-messages":errors,"dense":"","clearable":"","data-cy":"project-id"},on:{"change":function($event){return _vm.updateProject($event)}},model:{value:(_vm.form.project_number),callback:function ($$v) {_vm.$set(_vm.form, "project_number", $$v)},expression:"form.project_number"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('StudyForm.project_name'),"value":_vm.project.name,"disabled":"","filled":"","hide-details":"","data-cy":"project-name"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('StudyForm.brand_name'),"value":_vm.project.brand_name,"disabled":"","filled":"","hide-details":"","data-cy":"brand-name"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Number","rules":("numeric|max:" + (_vm.userData.studyNumberLength))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"id":"studyNumber","label":_vm.$t('StudyForm.number'),"error-messages":errors,"dense":"","clearable":"","data-cy":"study-number"},model:{value:(_vm.form.study_number),callback:function ($$v) {_vm.$set(_vm.form, "study_number", $$v)},expression:"form.study_number"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Acronym","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"id":"studyAcronym","label":_vm.$t('StudyForm.acronym'),"error-messages":errors,"dense":"","clearable":"","data-cy":"study-acronym"},model:{value:(_vm.form.study_acronym),callback:function ($$v) {_vm.$set(_vm.form, "study_acronym", $$v)},expression:"form.study_acronym"}})]}}])})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":_vm.$t('StudyForm.study_id'),"value":_vm.studyId,"disabled":"","filled":"","hide-details":"","data-cy":"study-id"}})],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }