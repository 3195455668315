import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":_vm.backgroundColor}},[(_vm.message)?_c(VCardText,{staticClass:"pt-2 white--text"},[_c(VRow,{staticClass:"align-center pa-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"white","x-large":""}},[_vm._v(_vm._s(_vm.icon))])],1),_c(VCol,{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-body-1 mt-1"},[_vm._v(" "+_vm._s(_vm.message)+" ")])])],1),_c(VDivider,{staticClass:"pa-2"}),_c(VRow,[_c(VCol,{staticClass:"text-center"},[(!_vm.options.noCancel)?_c(VBtn,{staticClass:"mr-4 warning",attrs:{"color":"white","data-cy":"cancel-popup","elevation":"4"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.options.cancelLabel)+" ")]):_vm._e(),_vm._t("actions",function(){return [(!_vm.options.noAgree && _vm.options.redirect === '')?_c(VBtn,{staticClass:"warning darken-1",attrs:{"color":"white","data-cy":"continue-popup","elevation":"4"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.options.agreeLabel)+" ")]):_c(VBtn,{staticClass:"warning darken-2",attrs:{"color":"white","data-cy":"continue-popup","elevation":"4"},nativeOn:{"click":function($event){return _vm.agreeAndRedirect.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.options.agreeLabel)+" ")])]})],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }