import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"value":_vm.open,"scrollable":"","persistent":"","max-width":_vm.maxWidth},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}}},[_c(VCard,{attrs:{"data-cy":"form-body","elevation":"0"}},[_c(VCardTitle,[_c('span',{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.title))]),_c('help-button-with-panels',{attrs:{"title":_vm.$t('_global.help'),"help-text":_vm.helpText,"items":_vm.helpItems}}),(_vm.formUrl)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"secondary","small":""},on:{"click":_vm.copyUrl}},[_vm._v(" "+_vm._s(_vm.$t('_global.copy_link'))+" ")]):_vm._e()],1),_c(VDivider),_c(VCardText,[_c('div',[_vm._t("body")],2)]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c('div',[_vm._t("actions")],2),_c(VBtn,{staticClass:"secondary-btn",attrs:{"data-cy":"cancel-button","disabled":_vm.actionDisabled,"outlined":"","elevation":"2","width":"120px"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('_global.cancel'))+" ")]),(!_vm.noSaving)?_c(VBtn,{attrs:{"data-cy":"save-button","color":"secondary","loading":_vm.working,"disabled":_vm.actionDisabled,"elevation":"2","width":"120px"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('_global.save'))+" ")]):_vm._e()],1)],1)],1),_c('confirm-dialog',{ref:"confirm",attrs:{"text-cols":6,"action-cols":5}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }