import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"data-cy":"form-body","color":"white"}},[_c(VCardTitle,[_c('span',{staticClass:"dialog-title"},[_vm._v(_vm._s(_vm.$t('StudyQuickSelectForm.title')))])]),_c(VCardText,[_c('validation-observer',{ref:"observer"},[_c(VRow,{staticClass:"mt-4"},[_c(VCol,{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"atleastone:@study_id","vid":"study_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VAutocomplete,{attrs:{"label":_vm.$t('StudyQuickSelectForm.study_id'),"items":_vm.studiesWithId,"item-text":"current_metadata.identification_metadata.study_id","return-object":"","error-messages":errors,"clearable":""},on:{"change":_vm.autoPopulateAcronym},model:{value:(_vm.studyById),callback:function ($$v) {_vm.studyById=$$v},expression:"studyById"}})]}}])})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"atleastone:@study_acronym","vid":"study_acronym"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VAutocomplete,{attrs:{"label":_vm.$t('StudyQuickSelectForm.study_acronym'),"items":_vm.studiesWithAcronym,"item-text":"current_metadata.identification_metadata.study_acronym","return-object":"","error-messages":errors,"clearable":""},on:{"change":_vm.autoPopulateId},model:{value:(_vm.studyByAcronym),callback:function ($$v) {_vm.studyByAcronym=$$v},expression:"studyByAcronym"}})]}}])})],1)],1)],1)],1),(_vm.expand || _vm.expand2)?_c(VSpacer,{staticClass:"distance"}):_vm._e(),_c(VCardActions,{staticClass:"pb-4"},[_c(VSpacer),_c(VBtn,{staticClass:"secondary-btn",attrs:{"color":"white","elevation":"3"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('_global.cancel'))+" ")]),_c(VBtn,{attrs:{"color":"secondary","elevation":"3"},on:{"click":_vm.select}},[_vm._v(" "+_vm._s(_vm.$t('_global.ok'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }