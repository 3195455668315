<template>
<v-card color="dfltBackground">
  <v-card-title class="dialog-title">
    {{ $t('About.title') }}
  </v-card-title>
  <v-card-text>
    <div class="body-content">
      <div class="body-content_logo"><img src="../../assets/rcc.png" alt="RCC logo" /></div>
      <div class="body-content_build-data">
        <div>
          Copyright © 2015-2023, nPhase, Inc. All rights reserved.
        </div>
        <div>
          <table>
            <tr>
              <td>Version: </td>
              <td>0.8.1</td>
            </tr>
            <tr>
              <td>Build Number:</td>
              <td>26</td>
            </tr>
            <tr>
              <td>Build Date: </td>
              <td>2023-10-09, 11:29</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
      class="secondary-btn"
      color="white"
      @click="$emit('close')"
      >
      {{ $t('_global.close') }}
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import system from '@/api/system'

export default {
  data () {
    return {
      components: [
        {
          name: this.$t('About.frontend'),
          description: this.$t('About.frontend_description'),
          build_number: this.$config.FRONTEND_BUILD_NUMBER
        },
        {
          name: this.$t('About.documentation'),
          description: this.$t('About.documentation_description'),
          build_number: this.$config.DOCUMENTATION_PORTAL_BUILD_NUMBER
        },
        {
          name: this.$t('About.api'),
          description: this.$t('About.api_description'),
          build_number: this.$config.API_BUILD_NUMBER
        },
        {
          name: this.$t('About.database'),
          description: this.$t('About.db_description')
        }
      ]
    }
  },
  mounted () {
    system.getInformation().then(response => {
      this.$set(this.components[3], 'build_number', response.data.db_version)
    })
  }
}
</script>

<style type="scss" scoped>
  .body-content {
    display: flex;
    flex-direction: column;
  }

  .body-content_logo {
    text-align: center;
    margin-top: 10px;
  }

  .body-content_build-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
</style>
