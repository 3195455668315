const DESCRIPTION_METADATA = '+current_metadata.study_description'
const HIGH_LEVEL_STUDY_DESIGN_METADATA = '+current_metadata.high_level_study_design'
const INTERVENTION_METADATA = '+current_metadata.study_intervention'
const POPULATION_METADATA = '+current_metadata.study_population'

const STOP_RULE_NONE = 'NONE'

const TERM_NOT_APPLICABLE = 'C48660_NA'

const STUDY_TIME_UNIT_DAY = 'day'
const STUDY_TIME_UNIT_WEEK = 'week'

export default {
  DESCRIPTION_METADATA,
  HIGH_LEVEL_STUDY_DESIGN_METADATA,
  INTERVENTION_METADATA,
  POPULATION_METADATA,
  STOP_RULE_NONE,
  TERM_NOT_APPLICABLE,
  STUDY_TIME_UNIT_DAY,
  STUDY_TIME_UNIT_WEEK
}
