import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"greyBackground"}},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('Settings.title')))]),_c('help-button-with-panels',{attrs:{"title":_vm.$t('_global.help'),"items":_vm.helpItems}})],1),_c(VCardText,[_c('div',{staticClass:"white pa-4"},[_c(VSelect,{attrs:{"label":_vm.$t('Settings.rows'),"items":_vm.rows,"item-text":"name","item-value":"value","clearable":""},model:{value:(_vm.form.rows),callback:function ($$v) {_vm.$set(_vm.form, "rows", $$v)},expression:"form.rows"}}),_c(VTextField,{attrs:{"label":_vm.$t('Settings.study_number_length'),"type":"number","clearable":""},model:{value:(_vm.form.studyNumberLength),callback:function ($$v) {_vm.$set(_vm.form, "studyNumberLength", $$v)},expression:"form.studyNumberLength"}}),_c(VSwitch,{staticClass:"mt-4",attrs:{"label":_vm.$t('Settings.multilingual_crf'),"data-cy":"settings-multilingual-crf"},model:{value:(_vm.form.multilingual),callback:function ($$v) {_vm.$set(_vm.form, "multilingual", $$v)},expression:"form.multilingual"}})],1)]),_c(VCardActions,{staticClass:"pb-4"},[_c(VSpacer),_c(VBtn,{staticClass:"secondary-btn",attrs:{"color":"white"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('_global.cancel'))+" ")]),_c(VBtn,{attrs:{"color":"secondary","data-cy":"save-settings-button","loading":_vm.working},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('_global.save'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }