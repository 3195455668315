import { VApp } from 'vuetify/lib/components/VApp';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('topbar',{attrs:{"hide-app-bar-nav-icon":_vm.layoutTemplate === 'empty'},on:{"backToRoot":_vm.navigateToRoot}}),(_vm.layoutTemplate === 'empty')?[_c(VMain,{staticClass:"primary white--text"},[_c('router-view')],1)]:(_vm.layoutTemplate === 'error')?[_c(VMain,{},[_c('router-view')],1)]:[_c('sidebar'),_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c(VBreadcrumbs,{staticClass:"mb-2",attrs:{"items":_vm.breadcrumbs}}),_c('router-view')],1)],1)],_c(VSnackbar,{attrs:{"color":_vm.notificationColor,"timeout":_vm.notificationTimeout,"min-width":550,"top":"","centered":"","tile":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"large":""}},[_vm._v(_vm._s(_vm.notificationIcon))])],1),_c(VCol,{attrs:{"cols":"10"}},[_c('div',{staticClass:"text-body-1 mt-1"},[_vm._v(" "+_vm._s(_vm.notification)+" "),(_vm.correlationId)?[_c('p'),_c('p',{staticClass:"text-body-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('_global.correlation_id')))]),_c('br'),_vm._v(" "+_vm._s(_vm.correlationId)+" ")])]:_vm._e()],2)]),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"fab":"","color":"white","small":"","icon":""},on:{"click":function($event){_vm.snackbar = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }