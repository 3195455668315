import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"color":"dfltBackground"}},[_c(VCardTitle,{staticClass:"dialog-title"},[_vm._v(" "+_vm._s(_vm.$t('About.title'))+" ")]),_c(VCardText,[_c('div',{staticClass:"body-content"},[_c('div',{staticClass:"body-content_logo"},[_c('img',{attrs:{"src":require("../../assets/rcc.png"),"alt":"RCC logo"}})]),_c('div',{staticClass:"body-content_build-data"},[_c('div',[_vm._v(" Copyright © 2015-2023, nPhase, Inc. All rights reserved. ")]),_c('div',[_c('table',[_c('tr',[_c('td',[_vm._v("Version: ")]),_c('td',[_vm._v("0.8.1")])]),_c('tr',[_c('td',[_vm._v("Build Number:")]),_c('td',[_vm._v("26")])]),_c('tr',[_c('td',[_vm._v("Build Date: ")]),_c('td',[_vm._v("2023-10-09, 11:29")])])])])])])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"secondary-btn",attrs:{"color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('_global.close'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }