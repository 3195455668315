
const NUM_VALUE = 'NumericValue'
const TEXT_VALUE = 'TextValue'
const NULL = 'null'
const ENG = 'ENG'

export default {
  NUM_VALUE,
  TEXT_VALUE,
  NULL,
  ENG
}
